import React, { useRef, useEffect } from 'react';
import { AppBar, Toolbar, CssBaseline, Container, Typography, Box, Stack, IconButton, TextField, InputAdornment, Button, Grid, GlobalStyles } from '@mui/material';
import { Canvas, useFrame } from '@react-three/fiber';
import { OrbitControls, useGLTF } from '@react-three/drei';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import SendIcon from '@mui/icons-material/Send'; // Import Send icon
import './fonts.css';

const globalStyles = <GlobalStyles styles={{
  body: {
    background: 'linear-gradient(0deg, rgba(73, 3, 252,1) 0%, rgba(255,255,255,1) 30%)', // Dark blue to white gradient
  },
}} />;

function Model({ path }) {
  const modelRef = useRef();
  const { scene } = useGLTF(path);
  scene.scale.set(0.025, 0.025, 0.025); // Adjust model scaling as needed

  useFrame(() => {
    if (modelRef.current) {
      modelRef.current.rotation.y += 0.005; // Adjust rotation speed as needed
    }
  });

  useEffect(() => {
    if(scene) {
      modelRef.current = scene;
    }
  }, [scene]);

  return <primitive object={scene} dispose={null} />;
}
function ComingSoon() {
  return (
    <>
      {globalStyles}
      <CssBaseline />
      <AppBar position="static" color="default" elevation={0}>
        <Toolbar>
          <img src="/models/studipath-logo-web.png" alt="Logo" style={{ height: '40px' }} />
        </Toolbar>
      </AppBar>
      <Container maxWidth="lg" style={{ height: 'calc(100vh - 64px)', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <Typography variant="h3" component="h2" gutterBottom style={{ fontFamily: 'FacundoBlack, sans-serif', textAlign: 'center', marginBottom: '1rem' }}>
          Coming Soon
        </Typography>
        <Typography variant="body1" gutterBottom style={{ fontFamily: 'FacundoRegular, sans-serif', textAlign: 'center', marginBottom: '1.5rem' }}>
          Join our mailing list for latest.
        </Typography>
        <Grid container spacing={2} sx={{ width: '100%', marginBottom: '2rem' }}>
          <Grid item xs={10}>
            <TextField
              fullWidth
              label="Email Address"
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <MailOutlineIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Button fullWidth variant="contained" color="primary" endIcon={<SendIcon />} style={{'height':'100%', 'backgroundColor': '#4903fc'}}>
            </Button>
          </Grid>
        </Grid>
        <Box sx={{ width: '100%', height: '50vh', display: 'flex', justifyContent: 'center' }}>
          <Canvas camera={{ position: [3, 3, 3], fov: 75 }}>
            <ambientLight intensity={0.5} />
            <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} />
            <Model path="models/scene.gltf" />
            <OrbitControls />
          </Canvas>
        </Box>
        {/* Social Media Buttons */}
        <Stack direction="row" spacing={2} justifyContent="center" sx={{ marginTop: 4 }}>
          <IconButton aria-label="Twitter" onClick={() => window.location.href = 'https://twitter.com/yourusername'}>
            <TwitterIcon />
          </IconButton>
          <IconButton aria-label="Facebook" onClick={() => window.location.href = 'https://facebook.com/yourusername'}>
            <FacebookIcon />
          </IconButton>
          <IconButton aria-label="LinkedIn" onClick={() => window.location.href = 'https://linkedin.com/in/yourusername'}>
            <LinkedInIcon />
          </IconButton>
        </Stack>
      </Container>
    </>
  );
}

export default ComingSoon;